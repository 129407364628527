
@import url("https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900&display=swap&subset=hebrew");
@import "~react-image-gallery/styles/css/image-gallery.css";




/*App.css*/

*{
  font-family: 'Heebo', sans-serif !important;
}
button:focus, button:hover , a:hover{
  outline: none;
  box-shadow: none;
  text-decoration: none;
}

.modal-dialog{
  min-width: 50%;
}

body{
  background-color:#f5f5f5;
}

.HoverStyle{
  border-bottom: 3px solid #dfa002 !important;
  padding-bottom: 0 !important;
}

.slick-track{
  align-items: center;
  display: flex !important;
  background-color: white;
}

.contentFixMarginCrm p{
  margin-right:0 !important;
}

.App{
  /*text-align: center;*/
  text-align: right;
  direction: rtl;
  max-width: 1400px;
  margin: 0 auto;
  font-family: 'Heebo', sans-serif !important;
}
span{
  font-family: 'Heebo', sans-serif !important;
}
.loader{
  height: 250px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.activeCat {
  border-color: red !important;
}
.activeCat span{
  border-color: #ea0000 transparent !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbarHeader{
  margin-top: 30px;
  border-top: 4px dotted black;
  border-bottom: 4px dotted black;
  padding: 15px;

}
a{
  color: black;
}

.page-link{
  color: black;
  border: 0;
}
.page-item.active .page-link{
  border-radius: 50%;
  background: black;
}

.page-link:hover{
  border-radius: 50%;
}

.paginationStyle{
  padding: 0;
  border: none;
  justify-content: center;
  display: flex;
  margin-top: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiList-root li:not(:first-child) {
  border-top: 4px dotted #bcbcbc;
}
.MuiList-root li{
  justify-content: right;
  display: grid;
  width: 200px;
}


